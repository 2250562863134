import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import OutsideClickHandler from 'react-outside-click-handler';

export default function NavbarButtons(props) {


return (
<div className='nav-menu-wrapper'>
    {/* Mobile Menu Hamburger */}
    <div className='menu-icon' onClick={props.handleClick}>
        {/* Switch the icon from 'open' to 'close' relative to the 'click' variable */}
        <i className={props.click ? 'fas fa-times' : 'fas fa-bars'} />
    </div>

    {/* Switch to 'open' or 'close' when accessing an other page */}
    <ul className={props.click ? 'nav-menu active' : 'nav-menu'}>
        
        <MenuItem name="Home" location="" handleClick={props.handleClick} />
        
        {/* <MenuItem name="Missie / Visie" location="missievisie" handleClick={props.handleClick} /> */}
        
        {/* About */}
        <MenuItemFold index={0} name={"Over Ons"} baseLocation={'about'} click={props.click} closeFoldMenu={props.closeFoldMenu} handleClick={props.handleClick}>
            <MenuItem name="Bedrijf" location="about-company" handleClick={props.handleClick} className={'nav-item-foldable-item'}/>
            <MenuItem name="Projecten" location="about-projects" handleClick={props.handleClick} className={'nav-item-foldable-item'}/>
        </MenuItemFold>

        <MenuItem name="Machinepark" location="machinepark" handleClick={props.handleClick} />
        {/* <MenuItem name="Eigen Engineering" location="machinepark" handleClick={props.handleClick} /> */}

        
        {/* Needs to be a foldout menu */}
        {/* <MenuItem name="Services" location="production" handleClick={props.handleClick} /> */}

        {/* Services */}
        <MenuItemFold index={0} name={"Services"} baseLocation={'services'} click={props.click} closeFoldMenu={props.closeFoldMenu} handleClick={props.handleClick}>
            <MenuItem name="Assemblage" location="services-assembly" handleClick={props.handleClick} className={'nav-item-foldable-item'}/>
            <MenuItem name="Productie" location="services-production" handleClick={props.handleClick} className={'nav-item-foldable-item'}/>
            {/* <MenuItem name="Engineering" location="services-production" handleClick={props.handleClick} className={'nav-item-foldable-item'}/> */}
            {/* <MenuItem name="Nabehandeling" location="services-production" handleClick={props.handleClick} className={'nav-item-foldable-item'}/> */}
        </MenuItemFold>
            
        {/* Nieuws */}
        {/* <MenuItemFold index={0} name={"Nieuws"} baseLocation={'nieuws'} click={props.click} closeFoldMenu={props.closeFoldMenu} handleClick={props.handleClick}> */}
            {/* <MenuItem name="Nieuws" location="nieuws-items" handleClick={props.handleClick} className={'nav-item-foldable-item'}/> */}
            {/* <MenuItem name="Vacatures" location="nieuws-vacatures" handleClick={props.handleClick} className={'nav-item-foldable-item'}/> */}
            {/* <MenuItem name="" location="services-production" handleClick={props.handleClick} className={'nav-item-foldable-item'}/> */}
        {/* </MenuItemFold> */}

        <MenuItem name="Nieuws" location="nieuws-items" handleClick={props.handleClick} />

            {/* <MenuItem name="Assemblage" location="assembly" handleClick={props.handleClick} /> */}
            {/* <MenuItem name="Productie" location="production" handleClick={props.handleClick} /> */}
        
        {/* <MenuItem name="Klanten" location="klanten" handleClick={props.handleClick} /> */}
        
        <MenuItem name="Vacatures" location="vacatures" handleClick={props.handleClick} />
        {/* <MenuItem name="Nieuws" location="contact" handleClick={props.handleClick} /> */}
        
        <MenuItem name="Contact" location="contact" handleClick={props.handleClick} />
    </ul>
</div>
);
}

export function MenuItem(props) {
    let _active = false;
    const _className = props.className == undefined ? '' : props.className;

    const baseLocation = `${window.baseLocation}`;

    // const _location = `${baseLocation}${props.location}`;
    const _location = `${props.location}`;
    // Check current path and set to active when true
    const _locationPath = window.publicURL + window.location.pathname;
    const _currentPath = (_locationPath ?  _locationPath : "");

    // console.log(_location, window.publicURL + window.location.pathname);
    if (`${window.publicURL}/${_location}` == _currentPath) {
        _active = true;
    }

    const onClick = (props.unavailable ? () => {} : () => {props.handleClick({click: true, path: props.location})});

    return (
        <li className={`${props.unavailable ? 'unavailable2' : ''} ${props.smallButton ? 'nav-item nav-item-small' : 'nav-item'} ${_className}`}>
            <Link title={props.unavailable ? 'Pagina is onder constructie' : ''} to={_location} className={`nav-links ${_active == true ? 'nav-links-active' : ''}`} onClick={() => {onClick(); if (props.onMyClick) props.onMyClick()}}>
                  <div>{props.name}</div>
            </Link>
        </li>
  );
}

export function MenuItemFold(props) {
    const [menuOpen, setMenuOpen] = useState(false);
    const [arrowActive, setArrowActive] = useState(false);

    const _baseLocation = props.baseLocation;// To check for the menu arrow
    const _url = document.location.pathname;

    useEffect(() => {
        if (_baseLocation && _url.includes(_baseLocation)) {
            if (arrowActive != true)
            setArrowActive(true);
        } else {
            if (arrowActive != false)
            setArrowActive(false);
        }
    });

    // console.log('Menu open', menuOpen);
    
// console.log(document.URL);
    // TODO: research why the compilers turns the name type of the item to 'h'
    const localIP = '192.168.56.1';
    const menuItemName = (document.URL.includes('localhost') || document.URL.includes(localIP)) ? 'MenuItem' : "k";//MenuItem
    const propsMenuItems = props.children;
    let menuItems = [];

    // Add the "setMenuOpen" to all MenuItem elements
    if (propsMenuItems) {
        let _i = 0;
        if (propsMenuItems.length > 1) {
            propsMenuItems.forEach(item => {
                console.log('name', item, item.type.name);
                if (item.type.name == menuItemName) {
                    const _item = React.cloneElement(item, {...item.props, onMyClick: () => {
                        if (menuOpen == true) {
                            setMenuOpen(false);
                        }
    
                    }, key: _i});
                    // menuItems[_i] = item;
                    menuItems.push(_item);
                    _i++;
                }
            });
        } else {
            const item = propsMenuItems;
            // console.log('name', item, item.type.name);
            if (item.type.name == menuItemName) {
                const _item = React.cloneElement(item, {...item.props, onMyClick: () => {
                    if (menuOpen == true) {
                        setMenuOpen(false);
                    }

                }, key: _i});
                // menuItems[_i] = item;
                menuItems.push(_item);
                _i++;
            }
        }
    }

    let _active = menuOpen;
    let _activeArrow = arrowActive;
    const _activeClassName = menuOpen ? 'active' : '';
    const _activeClassNameArrow = _activeArrow ? 'active-color' : '';
    

// props.closeMobileMenu
    return (<>
        <OutsideClickHandler onOutsideClick={() => {
            if (menuOpen) {
                // alert("Click");
                // if (window.innerWidth < 850) 
                setMenuOpen(false);
            }
             
            }}>
          <li className={`nav-item nav-item-foldable ${_activeClassName}`}>
            <div className={`nav-links nav-links-foldable ${_activeClassName}`} onClick={() => {setMenuOpen(!menuOpen)}}>
                <div style={{textAlign: "center", display: "block", width: "100%"}}>{props.name}</div>
                <i className={`fas fa-chevron-down foldable-menu-arrow ${_activeClassName} ${_activeClassNameArrow}`}></i>
            </div>

            {/* {_listJSX} */}
            <ul className={`nav-item-foldable-item-list ${_activeClassName}`}>
                {/* {menuOpen ? (props.index == 0 ? _listJSX : _listJSX2) : ''} */}
                {menuOpen ? <>
                {menuItems.map((item) => {
                    return <>{item}</>;
                })}
                </> : ''}
            </ul>
            
          </li>
        </OutsideClickHandler>
    </>);
  }